<template>
	<div>
		<div class="content backColor flex-space-between">
			<div class="tabs_body_f">
				<div class="tabs_body">
					<span>分类</span>
					<div class="pointer_s" v-for="(item,index) in tabsList.classify" :key="index"
							 :class="item.id==chooseIndex ? 'choose' : 'unchoose'" @click="tabsClick(item.id,1)">
						{{ item.name }}
					</div>
				</div>
				<div class="tabs_body">
					<span>状态</span>
					<div class="pointer_s" v-for="(item,index) in tabsList.stateArr" :key="index"
							 :class="item.id==chooseIndex2 ? 'choose' : 'unchoose'" @click="tabsClick(item.id,2)">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="tabs_body_f">
				<el-input
					size="small"
					placeholder="请输入搜索内容"
					suffix-icon="el-icon-search"
					v-model="searchV"
					@change="getData(1)"
					class="right_input">
				</el-input>
			</div>
		</div>
		<div class="content  match_body">
			<div class="match_item" v-for="(item,index) in list.data" :key="index" @click="toMatchDetail(item)">
				<div class="match_content flex-space-between">
					<div class="flex" style="align-items: center">
						<img :src="item.cover" class="match_image">
						<div class="contentM">
							<div class="match_name one-line">
								{{ item.name }}
							</div>
							<div>
								<div class="content_text">比赛地点：{{ item.province + item.city + item.address }}</div>
								<div class="content_text">报名时间：{{ item.sign_start }}-{{ item.sign_end }}</div>
								<div class="content_text">比赛时间：{{ item.start_time }}-{{ item.end_time }}</div>
							</div>
						</div>
					</div>
					<!--					2 报名未开始 、3 报名中、4 报名已结束 、5 活动已开始、6 活动已结束-->
					<div class="pointer_s  match_btn flex-center" v-if="item.active_state==2">
						未开始
					</div>
					<div class="pointer_s  match_btn_un2 flex-center" v-if="item.active_state==3">
						报名中
					</div>

					<div class="pointer_s match_btn_un flex-center" v-else>
						{{ item.active_state == 4 ? "报名已结束" : item.active_state == 5 ? "活动已开始" : "活动已结束" }}
					</div>
				</div>
			</div>

			<Lack
				v-if=" list.data?.length == 0"
				text="暂无内容"
				minHeight="370px"
				imgWidth="102px"
				imgHeight="68px"
				:imgSrc="require('../../../assets/image/img/15.png')"
			></Lack>
		</div>
		<div class="pagination">
			<el-pagination @current-change="currentChange" :page-size="list.per_page" background layout="prev, pager, next"
										 :total="list.total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import {getActiveList, getClassifyList} from "@/service/api";
import Lack from "@/components/lack/lack.vue";

export default {
	name: "matchSignUp",
	components: {Lack},
	data() {
		return {
			chooseIndex: 0,
			chooseIndex2: 0,
			searchV: '',
			tabsList: {
				classify: [],
				stateArr: []
			},
			list: {
				data: [],
				per_page: 1,
				total: 0,
			}
		}
	},
	mounted() {
		this.getData(1)
		this.getClassify()
	},
	methods: {
		getClassify() {
			getClassifyList({
				type: 1//赛事
			}).then((res) => {
				this.tabsList = res.msg
			})
		},
		getData(page) {
			getActiveList({
				name: this.searchV,
				class_id: this.chooseIndex,
				state: this.chooseIndex2,
				page: page
			}).then((res) => {
				this.list = res.msg

			})
		},
		tabsClick(id, index) {
			if (index == 1) {
				this.chooseIndex = id
			} else {
				this.chooseIndex2 = id
			}
			this.getData(1)
		},
		// 页码发生改变
		currentChange(page) {
			this.getData(page)
		},
		toMatchDetail(item) {
			this.$router.replace({
				name: 'matchDetail',
				params: {
					info: JSON.stringify({
						id: item.id,
						type: 1,
						status: 2
					})
				}
			});
			// /trainDetail
		}
	}
}
</script>

<style scoped lang="less">
.backColor {
	background-color: #FFFFFF;
	align-items: flex-start;

	.tabs_body_f {
		padding: 22px 28px;

		::v-deep input::-webkit-input-placeholder {
			// color: red;
			font-size: 14px;
		}

		.tabs_body {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			span {
				font-size: 16px;
				color: #333333;
			}

			.unchoose {
				padding: 6px 14px;
				background: #FFFFFF;
				border-radius: 5px 5px 5px 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				margin-left: 20px;
			}

			.choose {
				padding: 6px 14px;
				background: #212F82;
				border-radius: 5px 5px 5px 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				color: #FFFFFF;
				margin-left: 20px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

}

.search_body {
	/*margin: 22px 28px;*/
}

/deep/ .el-pager li {
	font-weight: normal;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #212F82;
	color: #fff;
}

.pagination {
	display: flex;
	justify-content: center;
	margin-top: 29px;
}

.list-page {
	min-height: 263px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/deep/ .el-input__inner {
	border-radius: 29px !important; /* 设置圆角大小 */
}

.match_body {
	margin-top: 20px;

	.match_item {
		background: #FFFFFF;
		padding: 16px 18px;
		margin-bottom: 20px;

		.match_content {
			align-items: flex-end;

			.contentM {
				margin-left: 20px;

				.match_name {
					margin-bottom: 30px;
					width: 800px;
					height: 28px;
					font-weight: bold;
					font-size: 20px;
					color: #333333;
					line-height: 28px;

				}

				.content_text {
					height: 30px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					line-height: 30px;
				}
			}

			.match_image {
				width: 236px;
				height: 148px;
				border-radius: 5px;
				object-fit: cover;
			}

			.match_btn {
				width: 92px;
				height: 32px;
				background: #B4375F;
				border-radius: 5px 5px 5px 5px;
				font-size: 14px;
				color: #FFFFFF;
				margin-right: 17px;
			}

			.match_btn_un {
				width: 92px;
				height: 32px;
				background: #B8B8B8;
				border-radius: 5px 5px 5px 5px;
				font-size: 14px;
				color: #FFFFFF;
				margin-right: 17px;
			}

			.match_btn_un2 {
				width: 92px;
				height: 32px;
				background: #212F82;
				border-radius: 5px 5px 5px 5px;
				font-size: 14px;
				color: #FFFFFF;
				margin-right: 17px;
			}
		}

		&:nth-child(2n) {
			margin-right: 0;
		}
	}
}
</style>
